<template>
  <div class="wrapper-main__content">
    <div class="form-content pb-4" style="padding-top: 10px">
      <VocabularyTitle class="mb-0" :title="$t('sideBar.miscellaneous')" />

      <h2 class="h4">{{ $t('table.allowMassSpotsCopyForAgencies') }}</h2>
      <SpinnerLoader :loading="agenciesStatus" />
      <div v-if="agencies && agenciesStatus === 'success'">
        <b-button-enh :disabled="isBusy" :busy-state="isBusy" class="mr-2" @click="changeMassSpotsForAMASettings('allow-all')">
          {{ $t('table.allowForAll') }}
        </b-button-enh>
        <b-button-enh :disabled="isBusy" :busy-state="isBusy" @click="changeMassSpotsForAMASettings('disallow-all')">
          {{ $t('table.disallowForAll') }}
        </b-button-enh>
        <section class="overflow-auto mr-auto my-3 px-2 rounded-sm allow-mass-copy-section" style="max-height: 300px; width: 450px">
          <div v-for="ag in agenciesList" :key="ag.id" class="custom-control custom-checkbox my-1">
            <input :id="`agencies-mass-copy-${ag.id}`" v-model="ag.allow_mass_copy" type="checkbox" class="custom-control-input" />
            <label :for="`agencies-mass-copy-${ag.id}`" class="custom-control-label">
              {{ ag.name }}
            </label>
          </div>
        </section>
        <b-button-enh :disabled="isBusy" :busy-state="isBusy" @click="changeMassSpotsForAMASettings()">{{ $t('table.save') }}</b-button-enh>
      </div>

      <h2 class="h3 mt-5 text-danger">
        {{ $t('table.dangerZone') }}
        <b-form-checkbox v-model="disabledDangerZone" name="check-button" class="h5 ml-3" switch inline>{{ $t('table.locked') }}</b-form-checkbox>
      </h2>
      <fieldset class="border border-danger p-4 rounded-sm" :disabled="disabledDangerZone">
        <template v-if="canDetachCommercialsFromChannel">
          <h3 class="h4">{{ $t('table.detachCommercialsFromChannel') }}</h3>
          <p>{{ $t('table.detachCommercialsExplanation') }}</p>
          <b-button-enh @click="$bvModal.show('modal-detach-commercials-from-channel')">{{ $t('table.openForm') }}</b-button-enh>
          <ModalDetachCommercialsFromChannel />
          <hr />
        </template>

        <h3 class="h4">{{ $t('table.globalStop') }}</h3>
        <p>{{ $t('table.forManualControl') }}. {{ $t('table.globalStopExplanation') }}</p>
        <p>
          {{ $t('table.status') }}:
          <span :class="globalStopActivated ? 'border-danger' : 'border-success'" class="border-left pl-1" style="border-width: 3px !important">
            {{ globalStopActivated ? 'On' : 'Off' }}
          </span>
        </p>

        <b-button v-if="canDisableGlobalStop" @click="globalStopActivated ? disableGlobalStop() : activateGlobalStop()">
          {{ globalStopActivated ? $t('table.disable') : $t('table.activate') }}
        </b-button>

        <hr />

        <h3 class="h4">{{ $t('table.globalRecalculation') }}</h3>
        <p>{{ $t('table.globalRecalculationExplanation') }}</p>
        <b-button v-if="canStartGlobalRecalc" :disabled="recalculating" @click="calculateAllWgrpAndBudgets()">
          {{ recalculating ? $t('table.recalculating') : $t('table.recalculateAll') }}
        </b-button>
      </fieldset>
    </div>
  </div>
</template>

<script>
import VocabularyTitle from '@/components/VocabularyTitle';
import errorsHandler from '@/utils/errorsHandler';
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import BButtonEnh from '@/components/BButtonEnh.vue';
import miscellaneous from '@/store/miscellaneous/index';

export default {
  name: 'Miscellaneous',
  components: {
    VocabularyTitle,
    SpinnerLoader,
    BButtonEnh,
    ModalDetachCommercialsFromChannel: () => import('@/components/AdminMiscellaneous/ModalDetachCommercialsFromChannel.vue'),
  },
  data() {
    return {
      recalculating: false,
      globalStopActivated: null,
      disabledDangerZone: true,
      isBusy: false,
      agenciesList: [],
    };
  },
  computed: {
    ...mapGetters({
      agencies: 'getAgencies',
      agenciesStatus: 'getAgenciesStatus',
    }),
    canDetachCommercialsFromChannel() {
      return this.$checkPermissions('channel.detach_commercials');
    },
    canDisableGlobalStop() {
      return this.$checkPermissions('global_stop.disable');
    },
    canStartGlobalRecalc() {
      return this.$checkPermissions('mediaplan.budget_calculate_all');
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule('miscellaneous')) this.$store.registerModule('miscellaneous', miscellaneous);
  },
  created() {
    document.title = this.$i18n.t('sideBar.miscellaneous') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getGlobalStopStatus();
    this.loadAgencies();
  },
  destroyed() {
    this.$store.commit('clearAgenciesList');
    if (!module.hot && this.$store.hasModule('miscellaneous')) this.$store.unregisterModule('miscellaneous'); // don't unregister when HMR
  },
  methods: {
    async getGlobalStopStatus() {
      await this.$store.dispatch('GET_GLOBAL_STOP', {
        handler: (res) => {
          this.globalStopActivated = res.data?.data?.is_enabled;
        },
        handlerError: (errors) => {},
      });
    },

    async activateGlobalStop() {
      const modalResult = await this.$bvModal
        .msgBoxConfirm(this.$i18n.t('table.activateGlobalStop'), {
          title: this.$i18n.t('table.confirmAction'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-post-global-stop-modal',
        })
        .catch((err) => {});
      if (!modalResult) return;
      await this.$store.dispatch('POST_GLOBAL_STOP', {
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.success'),
          });
          this.getGlobalStopStatus();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
    },

    async disableGlobalStop() {
      const modalResult = await this.$bvModal
        .msgBoxConfirm(this.$i18n.t('table.disableGlobalStop'), {
          title: this.$i18n.t('table.confirmAction'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-global-stop-modal',
        })
        .catch((err) => {});
      if (!modalResult) return;
      await this.$store.dispatch('DELETE_GLOBAL_STOP', {
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.success'),
          });
          this.getGlobalStopStatus();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
    },

    async calculateAllWgrpAndBudgets() {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.fullPomRecalculationWillBePerformed')} ${this.$i18n.t('table.itWillTakeSomeTime')}`, {
          title: this.$i18n.t('table.confirmAction'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-recalculate-all-modal',
        })
        .then((value) => {
          if (value) {
            this.recalculating = true;
            this.$store.dispatch('POST_RECALCULATE_ALL_WGRP_BUDGETS', {
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.success'),
                });
                this.recalculating = false;
              },
              handlerError: (errors) => {
                errorsHandler(errors, this.$notify);
                this.recalculating = false;
              },
            });
          }
        })
        .catch(() => {});
    },

    async loadAgencies() {
      this.agenciesList = [];
      await this.$store.dispatch('GET_AGENCIES', { per_page: 1000 });
      if (this.agenciesStatus === 'success' && this.agencies?.data) {
        this.agenciesList = this.agencies.data;
      }
    },

    async changeMassSpotsForAMASettings(type) {
      const result = await this.$bvModal
        .msgBoxConfirm(this.$i18n.t('table.areYouSure'), {
          title: this.$i18n.t('table.confirmAction'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-mass-spot-ama-settings',
        })
        .catch((err) => {});
      if (!result || !this.agencies?.data) return;

      this.isBusy = true;
      let formData = {
        agency_ids: [],
      };
      if (type === 'allow-all') {
        formData.agency_ids = this.agencies.data.map((a) => a.id);
      } else if (type === 'disallow-all') {
        formData.agency_ids = [];
      } else {
        formData.agency_ids = this.agencies.data.filter((a) => a.allow_mass_copy).map((a) => a.id);
      }

      this.$store.dispatch('PUT_ALLOW_MASS_COPY_AGENCIES', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.success'),
          });
          this.isBusy = false;
          this.loadAgencies();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
          this.isBusy = false;
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
section.allow-mass-copy-section
  background-color: hsl(222deg, 14%, 98%)
[data-color-scheme=dark] section.allow-mass-copy-section
  background-color: hsl(222deg, 14%, 20%)
</style>
