// DYNAMIC VUEX MODULE
import { getGlobalStop, postGlobalStop, deleteGlobalStop, postDetachCommercialsFromChannel } from '../../api/apiServices';

export default {
  state: {},
  mutations: {},
  actions: {
    async GET_GLOBAL_STOP({ dispatch, commit }, payload) {
      await getGlobalStop(payload)
        .then((res) => payload?.handler(res))
        .catch((err) => payload?.handlerError(err));
    },
    async POST_GLOBAL_STOP({ dispatch, commit }, payload) {
      await postGlobalStop(payload)
        .then((res) => payload?.handler(res))
        .catch((err) => payload?.handlerError(err));
    },
    async DELETE_GLOBAL_STOP({ dispatch, commit }, payload) {
      await deleteGlobalStop(payload)
        .then((res) => payload?.handler(res))
        .catch((err) => payload?.handlerError(err));
    },

    async POST_DETACH_COMMERCIALS_FROM_CHANNEL({ dispatch, commit }, payload) {
      await postDetachCommercialsFromChannel(payload.channel_id, payload.formData)
        .then((res) => payload?.handler(res))
        .catch((err) => payload?.handlerError(err));
    },
  },
  getters: {},
};
